import React from "react";
import "./index.scss";
/** 配置项
  title 页面标题
  titleExtra 页面标题右侧拓展（比如checkbox）
  renderLeft 左侧内容
  breadCrumb 面包屑
  tabs 标签 [{value,label,icon?}]
  extra 右侧头部的右侧内容
  children 包裹的右侧内容
  className 自定义样式
  defaultActiveKey 默认tab显示
 * 
 */
const IconFont = Loader.loadBaseComponent("IconFont");

class TwoColumnLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: props.defaultActiveKey,
      changed: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.defaultActiveKey !== this.props.defaultActiveKey) {
      this.setState({activeKey: nextProps.defaultActiveKey});
    }
  }
  getActiveKey = key => {
    this.setState({ activeKey: key, changed: true }, () => {
      setTimeout(() => {
        this.props.getActiveKey && this.props.getActiveKey(key);
        this.setState({ changed: false });
      }, 300);
    });
  };

  render() {
    const {
      title,
      renderLeft = null,
      breadCrumb = null,
      tabs = null,
      extra = null,
      children,
      className,
      titleExtra,
      ...props
    } = this.props;
    const { activeKey, changed } = this.state;
    return (
      <div className={`column-layout ${className}`}>
        <header>
          <div className="c-title">
            <strong>{title}</strong>
            <div className='title-extra'>{titleExtra}</div>
          </div>
          <div className="c-header">
            {breadCrumb}
            {tabs &&
              !!tabs.length &&
              tabs.map(tab => {
                const {value,label,icon} = tab
                return (
                <span
                  className={value === activeKey ? "c-tab c-active" : "c-tab"}
                  key={value}
                  onClick={() => this.getActiveKey(value)}
                >
                  {!!icon && <IconFont type={icon} />}
                  {label}
                </span>
              )})}
            <span className='c-extra'>{extra}</span>
          </div>
        </header>
        <div className="c-content">
          {renderLeft && <div className="c-left-content">{renderLeft}</div>}
          <div className={changed ? "c-right-content out" : 'c-right-content out-in'}>{children}</div>
        </div>
      </div>
    );
  }
}

export default TwoColumnLayout;
